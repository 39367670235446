<template>
	<div class="h-full">
		<div class="sl-border">
			<el-table v-loading="loadding" :data="pageDataSource.Result" border highlight-current-row>
				<el-table-column width="55" label="序号">
					<template slot-scope="scope">
						<span>{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="CertFileName" label="文件URL">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['CertFileName']}}</span>
						<el-input v-else v-model="scope.row['CertFileName']" suffix-icon="el-icon-upload2" readonly
							@click.native="uploadClick(scope.$index)"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="CertName" label="资质文件名称">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['CertName']}}</span>
						<el-input v-else v-model="scope.row['CertName']"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="CertNo" label="证书编号">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['CertNo']}}</span>
						<el-input v-else v-model="scope.row['CertNo']"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="CreatedTime" label="有效期">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDate']}}</span>
						<el-date-picker v-else v-model="scope.row['EffectiveDate']" placeholder="选择日期" type="date"
							value-format="yyyy-MM-dd HH:mm:ss">
						</el-date-picker>
					</template>
				</el-table-column>
				<el-table-column prop="CreatedTime" label="是否效期控制">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateFlag']}}</span>
						<DictionarySelect v-else v-model="scope.row['EffectiveDateFlag']" datType='Int'
							dicTypeCode="YesOrNoCode">
						</DictionarySelect>
					</template>
				</el-table-column>
				<el-table-column prop="EffectiveDateWarnDays" label="过期预警天数">
					<template slot-scope="scope">
						<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateWarnDays']}}</span>
						<el-input v-else v-model="scope.row['EffectiveDateWarnDays']">
							<template slot="append">天</template>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button type="primary" size="small" style="margin-left:10px;"
							@click="certDownload(scope.row)">下载
						</el-button>
						<el-button @click="remove(scope.row)" size="small" type="text">删除</el-button>
						<el-button @click="edit(scope.row)" size="small" type="text"
							v-if="!scope.row.IsAdd">编辑</el-button>
						<el-button @click="save(scope.row)" size="small" type="text"
							v-if="scope.row.IsAdd">保存</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="page">
				<el-pagination @size-change="pageSizeChange" @current-change="currentChange"
					:current-page="queryParam.PageIndex" :page-sizes="[10, 30, 50, 100]"
					:page-size="queryParam.PageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="pageDataSource.TotalCount">
				</el-pagination>
				<el-button type="text" class="btn-refurbish" @click="refreshPage">
					刷新
				</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loadding: false,
				pageDataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					Params: {}
				},
			}
		},
		props: {
			pageUrl: {
				type: String,
				default: ''
			},
			relationId: {
				type: String,
				default: null
			},
		},
		watch: {
			relationId: function(newVal, oldVal) {
				if (newVal && newVal != oldVal) {
					this.initPage();
				}
			},
		},
		mounted() {
			this.initPage();
		},
		methods: {
			refreshPage() {
				this.initPage();
			},
			pageSizeChange(val) {
				this.queryParam.PageSize = val;
			},
			currentChange(val) {
				this.queryParam.PageIndex = val;
				this.initPage();
			},
			handleCurrentChange: function(val) {
				this.$emit("onSelectedRow", val);
			},
			initPage() {
				var _this = this;
				console.log(_this.relationId)
				if (!_this.relationId) {
					return;
				}
				var parmsData = {
					FieldLabel: '',
					FieldValue: _this.relationId,
					FieldName: "MemberId",
					FieldType: 5,
					FieldValueStart: "",
					FieldValueEnd: ""
				};
				_this.queryParam.Params.RelationId = parmsData;

				_this.$ajax.query(_this.pageUrl, "post", _this.queryParam, (result) => {

					_this.pageDataSource = result;

					if (_this.pageDataSource.Result) {
						var list = [];
						list.push({
							Key: 0,
							Value: "装货地"
						});
						list.push({
							Key: 5,
							Value: "卸货地"
						});
						list.push({
							Key: 10,
							Value: "装卸货地"
						});
						_this.pageDataSource.Result.forEach(item => {
							item.AddressTypeList = list;
						})
					}

				});
			},
			delete(row) {
				var _this = this;
				_this.$ajax.query("omsapi/memberinfo/editmembercert", "post", row, (result) => {
					if (result.IsSuccess) {
						this.initPage();
					} else {
						_this.Utils.messageBox(result.OperationDesc, "error");
						return false;
					}
				});

			},
			remove(row) {
				if (row.Id) {
					var _this = this;
					_this.$ajax.query("omsapi/memberinfo/deletemembercert?id=" + row.Id, "get", {}, (result) => {
						if (result.IsSuccess) {
							this.initPage();
						} else {
							_this.Utils.messageBox(result.OperationDesc, "error");
							return false;
						}
					});
				} else {
					this.pageDataSource.Result.remove(row);
				}
			},
			add(data) {
				if (!this.pageDataSource.Result) {
					this.pageDataSource.Result = [];
				}
				this.pageDataSource.Result.push(data);
			},
			save(row) {
				var _this = this;
				_this.$ajax.query("omsapi/memberinfo/createmembercert", "post", row, (result) => {
					if (result.IsSuccess) {
						this.initPage();
					} else {
						_this.Utils.messageBox(result.OperationDesc, "error");
						return false;
					}
				});


			}
		},
	}
</script>